import Logo from "../assets/Logo.svg";

function Navbar() {
  return (
    <>
      <nav className="w-full drop-shadow-sm fixed z-20 py-6">
        <div className="bg-white z-10 top-0 py-5 fixed w-full  px-4 sm:px-6 lg:px-24 mx-auto flex items-center justify-between">
          <img className="w-14" src={Logo} />
          <div className="hidden items-center gap-6 md:flex">
            <a
              href="/"
              className="text-paragraph text-sm font-medium tracking-wide duration-300 hover:text-black"
            >
              Home
            </a>
            {/* <a
              href="/product"
              className="text-paragraph font-medium tracking-wide duration-300 hover:text-black"
            >
              Products
            </a>
            <a
              href="/resources"
              className="text-paragraph font-medium tracking-wide duration-300 hover:text-black"
            >
              Resources
            </a> */}
            {/* <a
              href="/Blog"
              className="text-paragraph font-medium tracking-wide duration-300 hover:text-black"
            >
              Blogs
            </a> */}
            <a
              href="/#about"
              className="text-paragraph text-sm font-medium tracking-wide duration-300 hover:text-black"
            >
              About
            </a>
            <a
              href="/#features"
              className="text-paragraph text-sm font-medium tracking-wide duration-300 hover:text-black"
            >
              Features
            </a>
            <a
              href="/#awards"
              className="text-paragraph text-sm font-medium tracking-wide duration-300 hover:text-black"
            >
              Awards
            </a>
            <a
              href="/#team"
              className="text-paragraph text-sm font-medium tracking-wide duration-300 hover:text-black"
            >
              Team
            </a>
            <a
              href="/contact"
              className="text-paragraph text-sm font-medium tracking-wide duration-300 hover:text-black"
            >
              Contact
            </a>
            <a
              href="/#faqs"
              className="text-paragraph text-sm font-medium tracking-wide duration-300 hover:text-black"
            >
              FAQS
            </a>
          </div>
          <div>
            <img className="w-20" src='https://sochai.org/wp-content/uploads/2019/09/sochai-website-logo.png' />
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
