function Contact() {
  return (
    <>
      <section className="">
        <main className="xxl:max-w-8xl mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
          <div className="max-w-3xl pt-40 mx-auto text-center">
            <h2 className="text-heading text-center text-4xl font-semibold tracking-wide">
              Contact
            </h2>
            <h6 className="text-paragraph text-base mt-4 leading-relaxed tracking-wider">
            Whether you have a question about our products, want to learn more about our services, or simply want to say hello, we are here to help.
            </h6>
          </div>

          <div className="mt-20 grid lg:grid-cols-2 gap-12 xxl:gap-20">
            <div className="grid sm:grid-cols-2 lg:grid-cols-1">
              <div>
                <h3 className="text-heading mt-2 font-medium tracking-wide sm:text-3xl sm:leading-snug">
                  Get In Touch
                </h3>
                <div className="mt-8 flex items-start gap-4">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-8 h-8 fill-primary"
                      viewBox="0 0 512 512"
                    >
                      <path d="M424 80H88a56.06 56.06 0 00-56 56v240a56.06 56.06 0 0056 56h336a56.06 56.06 0 0056-56V136a56.06 56.06 0 00-56-56zm-14.18 92.63l-144 112a16 16 0 01-19.64 0l-144-112a16 16 0 1119.64-25.26L256 251.73l134.18-104.36a16 16 0 0119.64 25.26z" />
                    </svg>
                  </div>
                  <div>
                    <h2 className="text-heading text-lg font-medium tracking-wide">
                      Send us a message
                    </h2>
                    <a href="" className="text-primary text-lg font-medium">
                      contact@dupler.co
                    </a>
                  </div>
                </div>
                <div className="mt-8 flex items-start gap-4">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-8 h-8 fill-primary"
                      viewBox="0 0 512 512"
                    >
                      <path d="M391 480c-19.52 0-46.94-7.06-88-30-49.93-28-88.55-53.85-138.21-103.38C116.91 298.77 93.61 267.79 61 208.45c-36.84-67-30.56-102.12-23.54-117.13C45.82 73.38 58.16 62.65 74.11 52a176.3 176.3 0 0128.64-15.2c1-.43 1.93-.84 2.76-1.21 4.95-2.23 12.45-5.6 21.95-2 6.34 2.38 12 7.25 20.86 16 18.17 17.92 43 57.83 52.16 77.43 6.15 13.21 10.22 21.93 10.23 31.71 0 11.45-5.76 20.28-12.75 29.81-1.31 1.79-2.61 3.5-3.87 5.16-7.61 10-9.28 12.89-8.18 18.05 2.23 10.37 18.86 41.24 46.19 68.51s57.31 42.85 67.72 45.07c5.38 1.15 8.33-.59 18.65-8.47 1.48-1.13 3-2.3 4.59-3.47 10.66-7.93 19.08-13.54 30.26-13.54h.06c9.73 0 18.06 4.22 31.86 11.18 18 9.08 59.11 33.59 77.14 51.78 8.77 8.84 13.66 14.48 16.05 20.81 3.6 9.53.21 17-2 22-.37.83-.78 1.74-1.21 2.75a176.49 176.49 0 01-15.29 28.58c-10.63 15.9-21.4 28.21-39.38 36.58A67.42 67.42 0 01391 480z" />
                    </svg>
                  </div>
                  <div>
                    <h2 className="text-heading text-lg font-medium tracking-wide">
                      Give us a call
                    </h2>
                    <a href="" className="text-primary text-lg font-medium">
                      +977 9876543210
                    </a>
                  </div>
                </div>
              </div>
              <div>
                <h2 className="text-heading text-lg font-medium tracking-wide">
                  Follow us on
                </h2>
                <div className="mt-4 grid xs:grid-cols-2 gap-4 md:gap-8">
                  <a
                    href=""
                    className="p-4 rounded-2xl w-full bg-[#1781FE] hover:bg-primary duration-300 flex items-center justify-center"
                  >
                    <span className="text-white  text-lg font-medium">
                      Facebook
                    </span>
                  </a>
                  <a
                    href=""
                    className="p-4 rounded-2xl w-full bg-[#FF0076] hover:bg-primary duration-300 flex items-center justify-center"
                  >
                    <span className="text-white  text-lg font-medium">
                      Instagram
                    </span>
                  </a>
                  <a
                    href=""
                    className="p-4 rounded-2xl w-full bg-[#009EFF] hover:bg-primary duration-300 flex items-center justify-center"
                  >
                    <span className="text-white  text-lg font-medium">
                      Twitter
                    </span>
                  </a>

                  <a
                    href=""
                    className="p-4 rounded-2xl w-full bg-[#157EE7] hover:bg-primary duration-300 flex items-center justify-center"
                  >
                    <span className="text-white  text-lg font-medium">
                      Linked In
                    </span>
                  </a>
                </div>
              </div>
            </div>
            <div className="bg-primary/5 rounded-3xl p-4 xs:p-8">
              <form action="#" method="POST">
                <div className="p-1">
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="first-name"
                        className="block text-base text-paragraph"
                      >
                        First name
                      </label>
                      <input
                        type="text"
                        name="first-name"
                        id="first-name"
                        placeholder="John"
                        required
                        className="mt-1 border-primary/40 text-paragraph placeholder:text-primary/70 w-full rounded-xl border focus:border-primary/10 duration-300 bg-white p-3 text-sm tracking-wider outline-none"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="last-name"
                        className="block text-base text-paragraph"
                      >
                        Last name
                      </label>
                      <input
                        type="text"
                        name="last-name"
                        id="last-name"
                        placeholder="Doe"
                        required
                        className="mt-1 border-primary/40 text-paragraph placeholder:text-primary/70 w-full rounded-xl border focus:border-primary/10 duration-300 bg-white p-3 text-sm tracking-wider outline-none"
                      />
                    </div>

                    <div className="col-span-6">
                      <label
                        htmlFor="email-address"
                        className="block text-base text-paragraph"
                      >
                        Email address
                      </label>
                      <input
                        type="text"
                        name="email-address"
                        id="email-address"
                        placeholder="johndoe@example.com"
                        required
                        className="mt-1 border-primary/40 text-paragraph placeholder:text-primary/70 w-full rounded-xl border focus:border-primary/10 duration-300 bg-white p-3 text-sm tracking-wider outline-none"
                      />
                    </div>

                    <div className="col-span-6">
                      <label
                        htmlFor="subject"
                        className="block text-base text-paragraph"
                      >
                        Subject
                      </label>
                      <input
                        type="text"
                        name="subject"
                        id="subject"
                        className="mt-1 border-primary/40 text-paragraph placeholder:text-primary/70 w-full rounded-xl border focus:border-primary/10 duration-300 bg-white p-3 text-sm tracking-wider outline-none"
                        placeholder="Request for a project..."
                        required
                      />
                    </div>

                    <div className="col-span-6">
                      <label
                        htmlFor="message"
                        className="block text-base text-paragraph"
                      >
                        Message
                      </label>
                      <textarea
                        id="message"
                        name="message"
                        rows={4}
                        className="mt-1 border-primary/40 text-paragraph placeholder:text-primary/70 w-full rounded-xl border focus:border-primary/10 duration-300 bg-white p-3 text-sm tracking-wider outline-none"
                        placeholder="Hello, I have an idea ..."
                        defaultValue={""}
                        required
                      />
                    </div>
                  </div>
                  <div className="mt-6">
                    <button className="border-primary text-white group flex items-center gap-2 rounded-xl border px-10 py-3 text-base font-medium tracking-wide duration-300 bg-primary hover:bg-slate-700">
                      <span className="group-hover:text-white">
                        Send Message
                      </span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </main>
      </section>
    </>
  );
}

export default Contact;
