import * as React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import About from "./components/About";
import Navbar from "./layout/Navbar";
import Footer from "./layout/Footer";
import Product from "./components/Product";
import Blog from "./components/Blog";
import Resource from "./components/Resource";
import Blogdetail from "./components/Blogdetail";
import Projectdetail from "./components/Projectdetail";
import Contact from "./components/Contact";
import Privacy from "./components/others/Privacy";
import Terms from "./components/others/Terms";
import Careers from "./components/others/Careers";
import Invest from "./components/others/Invest";
import Licences from "./components/others/Licences";
import Testimonials from "./components/others/Testimonial";
import Nutriai from "./components/Nutriai";

function App() {
  return (
    
    <>

   
      <Navbar />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Contact" element={<Contact />} />
          {/* <Route path="/about" element={<About />} /> */}
          {/* <Route path="/product" element={<Product />} /> */}
          {/* <Route path="/blog" element={<Blog />} /> */}
          {/* <Route path="/blogdetail" element={<Blogdetail />} /> */}
          {/* <Route path="/resources" element={<Resource />} /> */}
          {/* <Route path="/Projectdetail" element={<Projectdetail />} /> */}
          {/* <Route path="/Privacy" element={<Privacy />} />
          <Route path="/Terms" element={<Terms />} />
          <Route path="/Careers" element={<Careers />} />
          <Route path="/Invest" element={<Invest />} />
          <Route path="/Licences" element={<Licences />} />
          <Route path="/Testimonials" element={<Testimonials />} />
          <Route path="/Nutriai" element={<Nutriai />} /> */}
        </Routes>
      </BrowserRouter>
      <Footer />
    
    </>
  );
}

export default App;
